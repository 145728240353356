<template>
  <div>
    <!-- 工商福验收 -->
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          <el-form :inline="true">
             <el-form-item label="工程编号/名称:" style="padding:0px"><el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchText"
          ></el-input></el-form-item>
         
          
           <span style="margin-right:20px"></span>
           <el-form-item label="安装施工队:" style="padding:0px"><el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.install"
          ></el-input></el-form-item>
          
          
           <span style="margin-right:20px"></span>
           <el-form-item label="现场管理员:" style="padding:0px"><el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.siteManager"
          ></el-input></el-form-item>
          <el-form-item label="验收人：" style="padding:0px">
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.acceptUser"
          ></el-input></el-form-item>
          
           <span style="margin-right:20px"></span>
           <el-form-item label="验收结果:" style="padding:0px"> <el-select
            v-model="searchBox.result"
            clearable
            placeholder="请选择"
            size="small"
            multiple
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select></el-form-item>
          
         
           <span style="margin-right:20px"></span>
           <el-form-item label="提交时间:" style="padding:0px"> <el-date-picker
              size="small"
              v-model="searchBox.time"
              type="datetimerange"
              style="width: 400px"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择验收时间"
            >
            </el-date-picker></el-form-item>
          
           
            <span style="margin-right:20px"></span>
             <el-form-item label="" style="padding:0px" label-width="0px">
             <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="search"
            >查询</el-button
          >
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="resetData"
            >重置</el-button
          >
             </el-form-item>
          
          </el-form>
        </div>
        <div class="table">
          
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
           height="calc(100vh - 435px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
             @sort-change="sort_change"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="install"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="result"
              label="验收结果"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
               {{row.result == 1 ? '合格' : '不合格'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="contractCode"
              label="验收照片"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div style="cursor: pointer" @click="pictureDetil(row)">
                  查看
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="locations"
              label="经纬度"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="acceptUser"
              label="验收人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
            ></el-table-column>
            <el-table-column label="操作" align="left" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  size="small"
                  @click="seeDetil(row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="历次验收信息"
      :visible.sync="detilDialog"
      width="45%"
      :close-on-click-modal="false"
    >
      <el-form ref="project" :model="project" size="mini">
        <!--数据表格-->
        <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%; margin: 15px 0"
          border
           
          :stripe="true"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
        >
          <el-table-column
            prop="contractCode"
            label="验收日期"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contractCode"
            label="验收结果"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contractCode"
            label="验收人"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contractCode"
            label="整改情况"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <div
                v-if="row.case == 1"
                style="cursor: pointer"
                @click="examine"
              >
                查看
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>
    <el-dialog
      title="表具信息"
      :visible.sync="chartDialog"
      width="45%"
      :close-on-click-modal="false"
    >
      <el-form ref="project" :model="project" size="mini">
        <p class="detil-code">
          表具信息
        </p>
        <el-row v-for="(item,index) in project.workIndustrySubsetList" :key="index">
          <el-col :span="6">
            <el-form-item label="表编号：" prop="positionCode">
              {{ item.gradeNumber }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="燃气表类型：" prop="positionCode">
              {{ item.type }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="表向：" prop="positionCode">
              {{ item.directionName}}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="燃气表厂家：" prop="positionCode">
              {{ item.venderName }}
            </el-form-item>
          </el-col>
        </el-row>
        <p class="detil-code">
          验收信息
        </p>
         <el-table
            ref="tableData"
            :data="project.workIndustryAcceptList"
            style="width: 100%; margin: 15px 0"
            border
             
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              prop="result"
              label="验收结果"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{row}">
                {{row.result==1?'合格':'不合格'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="acceptUser"
              label="验收人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="img"
              label="验收照片"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div style="cursor: pointer" @click="pictureDetil(row)">
                  查看
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>

            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="explain"
              label="附加描述"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
             <el-table-column
            prop="contractCode"
            label="整改情况"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <div
                v-if="row.case != 1 && row.result !=1"
                style="cursor: pointer"
                @click="examine(row)"
              >
                查看
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          </el-table>
      </el-form>
    </el-dialog>
    <el-dialog
     
      :visible.sync="examineDialogV"
      width="25%"
      :close-on-click-modal="false"
    >
      <el-form ref="zhenggaiproject" :model="zhenggaiproject" label-width="120px" size="mini">
        <div style="max-height: 600px; overflow: auto" v-if="condition">
          <p class="detil-code">整改单</p>
         
          <el-row>
            <el-col :span="24">
              <el-form-item label="整改原因：" prop="positionCode">
               {{zhenggaiproject.cause}}
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24">
              <el-form-item label="验收环节：" prop="positionCode">
                强度试验
              </el-form-item>
            </el-col>
          </el-row> -->
<!--          <el-row>-->
<!--            <el-col :span="24">-->
<!--              <el-form-item label="位置：" prop="positionCode">-->
<!--                {{ 12222222222212121 }}-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="不合格照片："
                prop="positionCode"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="img[0]"
                  :preview-src-list="img"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ img.length }}张</span
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div style="flex: 1" >
                <p class="detil-code">整改处理</p>
                <div style="padding: 10px">
                  <el-row  >
                  <el-col :span="24">
                    <el-form-item label="处理结果：" prop="positionCode" >
                      {{ state[zhenggaiproject.state] }}
                    </el-form-item>
                </el-col>
                </el-row>
                    <el-row  v-if="zhenggaiproject.state == 2">
                  <el-col :span="24">
                    <el-form-item label="相关证据：" prop="positionCode" >
                    <el-image
                      v-if="zhenggaiproject.explainImg"
                      style="width: 100px; height: 100px"
                      :src="zhenggaiproject.explainImg.split(',')[0]"
                      :preview-src-list="zhenggaiproject.explainImg.split(',')"
                    >
                    </el-image>
                    <span style="margin-left: 10px" v-if="zhenggaiproject.explainImg"
                      >共{{ zhenggaiproject.explainImg.split(",").length }}张</span
                    >
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row  v-if="zhenggaiproject.teams">
                  <el-col :span="24">
                    <el-form-item label="施工队：" prop="positionCode" >
                      {{ zhenggaiproject.teams.join('；') }}
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="操作人：" prop="positionCode" >
                      {{ zhenggaiproject.updateBy }}
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="操作时间：" prop="positionCode" >
                      {{ zhenggaiproject.updateTime }}
                    </el-form-item>
                </el-col>
                </el-row>
                </div>
              </div>
        <div style="max-height: 600px; overflow: auto" v-if="zhenggaiproject.state != 2">
          <p class="detil-code">整改情况</p>
          <div v-for="(item,index) in zhenggaiproject.workInspectionSgdSubsetList" :key="index">
            <el-row>
            <el-col :span="24">
              <el-form-item label="整改施工队：" prop="positionCode" >
                {{ item.workTeamName }}
              </el-form-item>
           </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" v-if="item.laterImg">
              <el-form-item
                label="整改后情况："
                prop="positionCode"
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item.laterImg.split(',')[0]"
                  :preview-src-list="item.laterImg.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ item.laterImg.split(',').length }}张</span
                >
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="24">
              <el-form-item label="整改说明：" prop="positionCode" >
                {{ item.explain }}
              </el-form-item>
            </el-col>
          </el-row>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
        title="验收图片"
        :visible.sync="pictureDialog"
        width="60%"
        :close-on-click-modal="false"
    >
      <el-image
          style="width: 100px; height: 100px"
          :src="tupian[0]"
          :preview-src-list="tupian"
      >
      </el-image>
      <span style="margin-left: 10px">
        共{{ tupian.length }}张
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getIndustry,getIndoorRectify} from "@/RequestPort/intranet";

export default {
  name: "lockout",
  data() {
    return {
      status: "12",
      activeName: "1",
      tupian:[],
      project: {},
      img:[],
      laterImg:[],
      zhenggaiproject: {
        workInspectionSgdSubsetList:{
          explain:''
        }
      },
      searchBox: {
        current: 1,
        size: 100,
        name: "",
        result: [],
        time:[]
      },
      total: 0,
      detilDialog: false,
      chartDialog: false,
      pictureDialog: false,
      examineDialogV:false,
      condition:true,
      typeList: [
        {
          label: "合格",
          value: 1,
        },
        {
          label: "不合格",
          value: 2,
        },
      ],
      state: {
        3: "已整改",
        2: "不整改",
        1: "进行整改",
        0: "未整改",
      },
      tableData: [{}],
    };
  },
  mounted() {
    this.loadList(this.searchBox);
  },
  methods: {
    sort_change(column){
      if(column.order=='ascending'){
        this.searchBox.sortType = 'asc';
      }else{
        this.searchBox.sortType = 'desc';
      }
      this.loadList(this.searchBox);
    },
    /**
     * 表具信息查看
     */
    examine(row){
      this.img = []
      this.examineDialogV = !this.examineDialogV;
      getIndoorRectify({id:row.id,source:5}).then(res=>{
        if(res.data.length > 0 ){
          this.zhenggaiproject = res.data[0]
            if(res.data[0].img){
              this.img = res.data[0].img.split(',')
            }
            // if(res.data[0].workInspectionSgdSubsetList && res.data[0].workInspectionSgdSubsetList.laterImg){
            //   this.laterImg = res.data[0].workInspectionSgdSubsetList.laterImg.split(',')
            // }
        }
      })
    },
    pictureDetil(row) {
      this.pictureDialog = !this.pictureDialog;
      if(row.img){
        this.tupian = row.img.split(',')
      }
    },
    // 获取详情
    seeDetil(row) {
      this.chartDialog = true;
      this.project = row
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    loadList(obj) {
      if(obj.time&&obj.time.length>0){
        obj.startDate = obj.time[0]
        obj.endDate = obj.time[1]
      }else{
         obj.startDate = ''
         obj.endDate = ''
      }
      getIndustry(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    resetData() {
      this.searchBox={
        current: 1,
        size: 100,
        name: "",
        result: [],
        searchText:'',
        install:'',
        time:[]
      }
      this.loadList(this.searchBox)
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  padding-bottom:10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
</style>